var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"d-flex align-center py-1"},[_c('v-text-field',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Recherche","color":"app-blue","prepend-inner-icon":"fas fa-search","outlined":"","hide-details":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchPrograms()}},model:{value:(_vm.search.quickSearch),callback:function ($$v) {_vm.$set(_vm.search, "quickSearch", $$v)},expression:"search.quickSearch"}}),_c('ProgramAutocomplete',{ref:"programAutocomplete",staticClass:"mr-2",attrs:{"label":"Programmes","flexGrow0":"","noDynamicSearch":"","multiple":""},on:{"input":function($event){return _vm.fetchPrograms()}},model:{value:(_vm.search.programs),callback:function ($$v) {_vm.$set(_vm.search, "programs", $$v)},expression:"search.programs"}}),_c('UserAutocomplete',{ref:"userAutocomplete",staticClass:"mr-2",attrs:{"label":"Intervenants","flexGrow0":"","noDynamicSearch":"","multiple":""},on:{"input":function($event){return _vm.fetchPrograms()}},model:{value:(_vm.search.intervenants),callback:function ($$v) {_vm.$set(_vm.search, "intervenants", $$v)},expression:"search.intervenants"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":""}},on),[_vm._v(" "+_vm._s(_vm.programsTotalCount)+" ")])]}}])},[_vm._v(" Nombre total de programmes ")]),_c('v-pagination',{attrs:{"length":_vm.programsTotalPages,"total-visible":"5","color":"app-blue"},on:{"input":function($event){return _vm.fetchPrograms(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-row',_vm._l((_vm.programs),function(program){return _c('v-col',{key:program.id,attrs:{"cols":"2"}},[_c('ManageProgramIntervenants',{attrs:{"programId":program.id},on:{"updated":function($event){return _vm.fetchPrograms()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',_vm._g({},on),[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"px-1 py-1"},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.getProgramPhotoUrl(program)}})],1)],1),_c('td',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-subtitle-2"},on),[_vm._v(" "+_vm._s(program.title)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(program.title)+" ")])],1),_c('td',{staticClass:"px-1"},[(program.intervenants.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"app-green","dark":"","small":""}},on),[_vm._v(" "+_vm._s(program.intervenants.length)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(program.intervenants.length)+" "+_vm._s(_vm._f("plural")(program.intervenants.length,'intervenant', 'intervenants'))+" ")]):_vm._e()],1),_c('td',{staticClass:"px-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [([-1, 1].includes(program.activated))?_c('v-btn',_vm._g({attrs:{"small":"","icon":"","rounded":"","color":_vm.getProgramBadgeColor(program)}},on),[_c('v-icon',{attrs:{"color":_vm.getProgramBadgeColor(program)}},[_vm._v("fas fa-check-circle")])],1):_vm._e()]}}],null,true)},[(program.activated == -1)?_c('span',[_vm._v("Désactivé")]):_vm._e(),(program.activated == 1)?_c('span',[_vm._v("Activé")]):_vm._e()])],1)])])])])]}}],null,true)})],1)}),1),_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{staticClass:"d-flex align-center px-2 mt-2 pb-0"},[_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.programsTotalPages,"total-visible":"5","color":"app-blue"},on:{"input":function($event){return _vm.fetchPrograms(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }